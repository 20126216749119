import * as React from "react";
import { useRoutes } from "react-router-dom";

// routes
import MainRoutes from "./MainRoutes";
import AuthRoutes from "./AuthRoutes";
import VerifyUserRoutes from "./VerifyUser";
// import DashboarRoutes from './DashboardRoutes'
import PageNotFoundRoutes from './NotFound';
// ==============================|| ROUTING RENDER ||============================== //

export default function ThemeRoutes() {
  return useRoutes([MainRoutes, AuthRoutes, PageNotFoundRoutes,VerifyUserRoutes]);
}
