import classes from "./policy.module.css";

const DemoUserPolicy = () => {
  return (
    <div style={{ minWidth: "100%" }}>
      <div>
        <div>
          <p>
          All information is provided "as is" with no warranty or representation.  We are not responsible for the performance of any third party.  Search results do not constitute endorsement or approval. 
          By using this web page, you agree to our use of the information you share with us and that we may contact you to follow up with you on your experience using the webpage and our search tools.
          </p>
        </div>
      </div>
    </div>
  );
};

export default DemoUserPolicy;
