import * as React from 'react';
import {
  Typography,
  MenuItem,
  Grid,
  Box,
  Divider,
  CircularProgress,
  IconButton,
  Menu,
  ListSubheader,
  Tooltip,
  Popper,
  Fade,
  Paper,
  Badge,
} from '@mui/material';

import NotificationIcon from '../common/NotificationIcon';
import useNotification from 'hooks/useNotification';
import classes from './Notification.module.css';
import { useSelector, useDispatch } from 'react-redux';
import {
  setShowNotificationPanel,
  setNotificationCounts,
} from '../../features/dashboardSlice';
import moment from 'moment';
import {
  markAsReadAllNotification,
  markSingleNotificationAsRead,
  markSingleNotificationAsUnRead,
  notificationPooling,
} from '../../services/apiHandler';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

//ROUTES
import { Link } from 'react-router-dom';
//ICONS
import MyProjectIcon from 'components/SVG/MyProjectIcon';
import CubeIcon from '../../components/SVG/CubeIcon';
import ConversationIcon from '../../components/SVG/ConversationIcon';
import DoneAllIcon from '@mui/icons-material/DoneAll';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import CloseIcon from '@mui/icons-material/Close';
import RemoveDoneIcon from '@mui/icons-material/RemoveDone';


const Notification = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const dashboardStore = useSelector((state: any) => state.dashboard);
  const [pageNumber, setPageNumber] = React.useState<number>(0);
  const {
    loading,
    error,
    notifications,
    setNotifications,
    fetchNotifications,
  } = useNotification(pageNumber);
  // const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  React.useEffect(() => {
    const intervalId = setInterval(() => {
      fetchNotifications(false); 
    }, 60000); 
    
    return () => clearInterval(intervalId);
  }, []);

  const [openActionMenuIndex, setOpenActionMenuIndex] = React.useState<
    number | null
  >(null);

  const [unReadAnchorEl, setUnReadAnchorEl] =
    React.useState<null | HTMLElement>(null);
  const anchorRef = React.useRef<HTMLDivElement>(null); // Use the appropriate ref type

  const [actionMenu, setActionMenu] = React.useState(false);

  const observer: any = React.useRef();
  const lastBookElement = React.useCallback(
    (node: any) => {
      if (observer.current) observer.current.disconnect();
      observer.current = new IntersectionObserver(
        (entries) => {
          if (entries[0].isIntersecting) {
            setPageNumber((prevPageNumber) => prevPageNumber + 10);
          }
        },
        {
          threshold: 1,
        }
      );
      if (node) observer.current.observe(node);
    },

    []
  );

  const handleMarkAllAsRead = async () => {
    try {
      let updateVal = notifications.map((item: any) => {
        item.read = true;
        return item;
      });
      setNotifications(updateVal);

      const markAllRead: any = await markAsReadAllNotification();
      if (markAllRead.status == 200) {
        fetchNotificationCounts();
        fetchNotifications(false);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const notificationCountsText = () => {
    if (dashboardStore.notificationCounts > 99) {
      return `99+`;
    }
    return dashboardStore.notificationCounts;
  };

  const notificationIcons = (notificationType: string) => {
    switch (notificationType) {
      case 'spendcube':
        return <CubeIcon className={classes.myProjectIcon} color={'#aaaaaa'} />;
      case 'conversation':
        return (
          <ConversationIcon
            className={classes.myProjectIcon}
            color={'#aaaaaa'}
          />
        );
      case 'file':
        return (
          <img
            src='/assets/images/file-icon.svg'
            alt='file'
            className={classes.notificationIcon}
          />
        );

      default:
        return (
          <MyProjectIcon className={classes.myProjectIcon} color={'#aaaaaa'} />
        );
    }
  };

  const handleClose = (event: React.MouseEvent, reason: string) => {
    if (reason == 'backdropClick') {
      dispatch(setShowNotificationPanel(false));
    }
  };

  const fetchNotificationCounts = () => {
    notificationPooling()
      .then((response: any) => {
        if (response && response.data && response.data.data) {
          const { notificationCount } = response.data.data;
          dispatch(setNotificationCounts(notificationCount));
        }
      })
      .catch((error: any) => {});
  };

  const markNotificationAsUnRead = async (notification: any) => {
    if (notifications && notifications.length > 0) {
      let updateVal = notifications.map((item: any) => {
        if (item._id == notification._id) {
          item.read = false;
        }
        return item;
      });
      setNotifications(updateVal);
    }

    try {
      let updateToUnRead: any = await markSingleNotificationAsUnRead(
        notification._id
      );
      if (updateToUnRead.status == 200) {
        fetchNotifications(false);
        fetchNotificationCounts();
      }
    } catch (error) {}
  };

  const markNotificationAsRead = async (notification: any) => {
    if (notifications && notifications.length > 0) {
      let updateVal = notifications.map((item: any) => {
        if (item._id == notification._id) {
          item.read = true;
        }
        return item;
      });
      setNotifications(updateVal);
    }
    try {
      let updateToRead: any = await markSingleNotificationAsRead(
        notification._id
      );
      if (updateToRead.status == 200) {
        fetchNotifications(false);
        fetchNotificationCounts();
      }
    } catch (error) {}
  };

  const handleOpenRead = (
    event: React.MouseEvent<HTMLElement>,
    index: number
  ) => {
    event.stopPropagation();
    setOpenActionMenuIndex(index);
    setUnReadAnchorEl(event.currentTarget);
  };

  const handleCloseRead = () => {
    setOpenActionMenuIndex(null);
    setUnReadAnchorEl(null);
  };

  const extractRoundNumber = (description: any) => {
    const match = description.match(/\b(?:round\s*|R\s*)([1-4])\b/i);
    return match ? parseInt(match[1], 10) : null;
  };

  const listEl = (notification: any, index: number) => {
    return (
      <React.Fragment>
        <MenuItem className={classes.menuItem} disableRipple>
          <div className={classes.menuItemContainer}>
            <Box className={classes.readBadge}>
              {' '}
              {notification.read == false ? (
                <Badge color='primary' variant='dot' />
              ) : (
                ''
              )}
            </Box>
            <Box className={classes.notiListContainer}>
              <Tooltip title={notification?.message} placement='bottom-start'>
                <div
                  className={classes.notificationTextContainer}
                  onClick={() => {
                    if (!notification.read) {
                      markNotificationAsRead(notification);
                    }
                    // navigate(notification.route);

                    let roundNumber = extractRoundNumber(notification.description);
                    console.log("Extracted Round Number:", roundNumber);
                    console.log("Original Route:", notification.route);
                 
                    // Ensure roundNumber is valid
                    if (roundNumber !== null && !isNaN(roundNumber)) {
                      let updatedRoute = notification.route;
                 
                      // Check if the route starts with "/create-rfi/rfq-suppliers"
                      if (notification.route.startsWith("create-rfi/rfq-suppliers")) {
                        updatedRoute += notification.route.includes("?") ? `&rfqRound=${roundNumber}` : `?rfqRound=${roundNumber}`;
                      }
                 
                      console.log("NoticationRound","NoticationRound",roundNumber,"path",updatedRoute);
                      navigate(updatedRoute);
                    } else {
                     
                      navigate(notification.route);
                    }

                   localStorage.setItem('projectId', notification.projectId);
                  }}
                >
                  <Typography
                    className={
                      notification.read ? classes.linkRead : classes.linkUnRead
                    }
                    sx={{
                      textDecoration: 'underline',
                      cursor: 'pointer',
                    }}
                    variant='body1'
                  >
                    {notification?.message}
                  </Typography>
                </div>
              </Tooltip>
              <Typography variant='body1' className={classes.projectDescText}>
                {notification?.description}
              </Typography>
              <Typography
                variant='body2'
                className={classes.notificationDateText}
              >
                {moment(notification?.createdDate).format(
                  'DD-MMM-YYYY [at] h:mm A'
                )}
              </Typography>
            </Box>
            <Box className={classes.notiListContainerAction}>
              {notificationIcons(notification.notificationType)}
            </Box>
          </div>
          <Box>
            <IconButton
              onClick={(event) => handleOpenRead(event, index)}
              disableRipple
            >
              <MoreVertIcon />
            </IconButton>
            {openActionMenuIndex === index && (
              <Menu
                anchorEl={unReadAnchorEl}
                open={openActionMenuIndex === index}
                onClose={handleCloseRead}
                className={classes.markAsReadUnReadMenu}
                transformOrigin={{ horizontal: 'right', vertical: 'top' }}
                anchorOrigin={{ horizontal: 'right', vertical: 'center' }}
                sx={{
                  '& .MuiPopover-paper': {
                    boxShadow: '1px 2px 3px 0px #d3d3d3 !important',
                  },             
                }}

              >
                {notification.read == false ? (
                  <Typography
                    onClick={() => markNotificationAsRead(notification)}
                    className={classes.markAsReadUnRead}
                  >
                    <DoneAllIcon
                      sx={{ fontSize: '1rem', mt: '4px', mr: '15px' }}
                    />

                    {t('Mark as read')}
                  </Typography>
                ) : (
                  <Typography
                    className={classes.markAsReadUnRead}
                    onClick={() => {
                      markNotificationAsUnRead(notification);
                    }}
                  >
                    <RemoveDoneIcon
                      sx={{ fontSize: '1rem', mt: '4px', mr: '15px' }}
                    />
                    {t('Mark as unread')}
                  </Typography>
                )}
              </Menu>
            )}
          </Box>
        </MenuItem>
        <Divider />
      </React.Fragment>
    );
  };
const meetsCondition = (obj:any) => obj.read === false;
const sortedArray:any = notifications.sort((a:any, b:any) => {
  if (meetsCondition(a) && !meetsCondition(b)) {
    return -1;
  }
 
  if (!meetsCondition(a) && meetsCondition(b)) {
    return 1;
  }
  return 0;
});

  return (
    <React.Fragment>
      <div>
        <NotificationIcon />
      </div>
      <Box>
        <Menu
          ref={anchorRef}
          open={dashboardStore.showNotificationPanel}
          onClose={handleClose}
          classes={{ paper: classes.menuContainer }}
          sx={{
            '& .MuiPaper-root': {
              top: '58px !important',
              right: '58px !important',
            },
            '& .MuiMenu-list':
            {
            paddingTop:'0',
            paddingBottom: '0',
            }
          }}
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
        >
          <ListSubheader className={classes.subHeaderContainer}>
            <Grid container className={classes.subHeader} >
              <Grid item xs={11} className={classes.notifiHeaderFirstSection}>
                <Typography
                  variant='body1'
                  className={classes.notificationTitle}
                >
                  Notifications{' '}
                  {dashboardStore.notificationCounts > 0 && (
                    <span className={classes.notiCountsBadge}>
                      {' '}
                      {notificationCountsText()}{' '}
                    </span>
                  )}
                </Typography>
                {notifications.length > 0 ? (
                  <Typography
                    variant='body2'
                    className={`${classes.markAsAllRead} app-theme-color ${
                      dashboardStore.notificationCounts > 0 ? '' : 'disabled'
                    }`}
                    onClick={handleMarkAllAsRead}
                  >
                    <DoneAllIcon
                      className={`${classes.markAsAllReadIcon } app-theme-color ${
                        dashboardStore.notificationCounts > 0 ? '' : 'disabled'
                      }`}
                    />{' '}
                    {t('Mark as all read')}
                  </Typography>
                ) : (
                  ''
                )}
              </Grid>
              <Grid item xs={1}>
                <CloseIcon
                  onClick={() => {
                    dispatch(setShowNotificationPanel(false));
                  }}
                  className={classes.closeIcon}
                />
              </Grid>
            </Grid>
          </ListSubheader>
          
          {sortedArray.length > 0 ? (
            sortedArray?.map((notification: any, index: number) => {
              if (sortedArray.length === index + 1) {
                return (
                  <div key={index} ref={lastBookElement}>
                    {listEl(notification, index)}
                  </div>
                );
              } else {
                return <div key={index}>{listEl(notification, index)}</div>;
              }
            })
          ) : (
            <div className={classes.noNotificationContainer}>
              <Typography variant='body1' className={classes.noNotification}>
                No new notifications
              </Typography>
            </div>
          )}

          {loading ? (
            <div className={classes.loadingContainer}>
              <CircularProgress size={20} />
            </div>
          ) : null}
        </Menu>
      </Box>
    </React.Fragment>
  );
};

export default Notification;
