// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".termsConditions_headingContainer__3TW2J {\n    display: flex;\n    justify-content: space-between;\n    height: 6%;\n}\n\n.termsConditions_conditionsContainer__b-vMT {\n    max-height: calc(100vh - 25rem);\n    overflow: auto;\n    margin-top: 1rem;\n    scroll-behavior: smooth;\n}\n\n.termsConditions_listItem__KVnlw {\n    margin-top: 1rem\n}\n\n.termsConditions_buttonContainer__MYQ-j {\n    display: flex;\n    justify-content: right;\n    align-items: center;\n    height: 12%\n}\n\n.termsConditions_buttonStyles__A04OS {\n    width: 8rem;\n    height: 2.5rem;\n}\n\n", "",{"version":3,"sources":["webpack://./src/components/models/termsConditionsDialog/termsConditions.module.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,8BAA8B;IAC9B,UAAU;AACd;;AAEA;IACI,+BAA+B;IAC/B,cAAc;IACd,gBAAgB;IAChB,uBAAuB;AAC3B;;AAEA;IACI;AACJ;;AAEA;IACI,aAAa;IACb,sBAAsB;IACtB,mBAAmB;IACnB;AACJ;;AAEA;IACI,WAAW;IACX,cAAc;AAClB","sourcesContent":[".headingContainer {\n    display: flex;\n    justify-content: space-between;\n    height: 6%;\n}\n\n.conditionsContainer {\n    max-height: calc(100vh - 25rem);\n    overflow: auto;\n    margin-top: 1rem;\n    scroll-behavior: smooth;\n}\n\n.listItem {\n    margin-top: 1rem\n}\n\n.buttonContainer {\n    display: flex;\n    justify-content: right;\n    align-items: center;\n    height: 12%\n}\n\n.buttonStyles {\n    width: 8rem;\n    height: 2.5rem;\n}\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"headingContainer": "termsConditions_headingContainer__3TW2J",
	"conditionsContainer": "termsConditions_conditionsContainer__b-vMT",
	"listItem": "termsConditions_listItem__KVnlw",
	"buttonContainer": "termsConditions_buttonContainer__MYQ-j",
	"buttonStyles": "termsConditions_buttonStyles__A04OS"
};
export default ___CSS_LOADER_EXPORT___;
