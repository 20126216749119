
import * as React from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import ButtonContained from 'components/ButtonContained/ButtonContained';
import CloseIcon from '@mui/icons-material/Close';
import classes from './termsConditions.module.css'
import { useTranslation } from 'react-i18next';
// policy documents
import TermsPolicy from 'components/policyDocs/TermsPolicy';
import PrivacyPolicy from 'components/policyDocs/PrivacyPolicy';
import CookiePolicy from 'components/policyDocs/CookiePolicy';
import DemoUserPolicy from 'components/policyDocs/DemoUserPolicy';

const style = {
    position: 'absolute' as 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '70%',
    maxHeight: '70%',
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
};



const TermsConditions = (props: any) => {

    const [open, setOpen] = React.useState(false);
    const { t } = useTranslation();

    React.useEffect(() => {

        setOpen(props.open);
    }, [props.open]);


    return (
        <>
            <Modal
                open={open}
                onClose={props.closeModal}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style}>
                    <Typography id="modal-modal-title" variant="h6" component="h2" className={classes.headingContainer}>
                        <strong>{props?.user? t("SupplyHub No Charge Supplier Search Disclaimer"):t('Terms and Conditions')}</strong>
                        <CloseIcon className='cursorPointer' onClick={() => props.closeModal()} />
                    </Typography>
                    <section id="modal-modal-description" className={classes.conditionsContainer}>
                        {props?.user?
                        <>
                         {/* {t("SupplyHub No Charge Supplier Search Disclaimer")} */}
                        <section id="terms-policy">
                        <DemoUserPolicy />
                        </section>
                        </>
                        :
                        <>
                        {t(" Here are just a few ways that having a Terms of Use for your SaaS app can benefit you:")}
                        <section id="terms-policy">
                            <TermsPolicy />
                        </section>
                        <section id="privacy-policy">
                            <PrivacyPolicy />
                        </section>
                        <section id="cookies-policy">
                            <CookiePolicy />
                        </section>
                        </>
                        }
                    </section>
                    <div className={classes.buttonContainer}>
                        <ButtonContained
                            className={classes.buttonStyles}
                            onClick={() => {
                                props.handleAcceptTerms()
                                props.closeModal()
                            }}>{t('Accept')}</ButtonContained>
                    </div>
                </Box>
            </Modal>
        </>

    )
}

export default TermsConditions